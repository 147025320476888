/* Landing Page CSS */

.header-color {
    background: #000046;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #1CB5E0, #000046);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #1CB5E0, #000046); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
  }
  
  .projects-grid {
    display: flex;
    padding-top: 30px;
  }
  
  .landing-grid {
    background: #000046;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #1CB5E0, #000046);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #1CB5E0, #000046); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .headShot-img {
    height: 300px;
    width: 300px;
    border-radius: 50%;
    margin-top: 5em;
  }
  
  
  .banner-text {
    background-color: black;
    opacity: .8;
    width: 75%;
    margin: auto;
    border-radius: 10px;
  }
  
  .banner-text h1 {
    font-size: 66px;
    font-weight: bold;
    color: white;
  }
  
  .banner-text hr {
    border-top: 5px dotted white;
    width: 50%;
    margin: auto;
  }
  
  .banner-text p {
    color: white;
    font-size: 20px;
    padding: 1em;
  }
  
  .social-links {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: auto;
  }
  
  .social-links i {
    color: white;
    font-size: 5em;
    padding: 8px;
  }